import { DialogService }              from 'aurelia-dialog';
import { bindable, inject }           from 'aurelia-framework';
import { I18N }                       from 'aurelia-i18n';
import { BaseListViewModel }          from 'base-list-view-model';
import { CreateListingRevisionModal } from 'modules/management/specifications/listings/listing-revisions/create';
import { EditListingRevisionModal }   from 'modules/management/specifications/listings/listing-revisions/edit';
import { ListingRevisionsRepository } from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { ViewListingRevisionModal }   from 'modules/management/specifications/listings/listing-revisions/view';
import { AppContainer }               from 'resources/services/app-container';
import { Downloader }                 from 'resources/services/downloader';

@inject(AppContainer, ListingRevisionsRepository, I18N, Downloader, DialogService)
export class ListListingRevisions extends BaseListViewModel {

    listingId = 'management-specifications-listings-revisions-listing';

    @bindable headerTitle    = null;
    @bindable newRecordRoute = 'management.specifications.listings.revisions.create';
    @bindable repository;
    @bindable datatable;
    @bindable listing;

    readonly = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param dialogService
     */
    constructor(appContainer, repository, i18N, downloader, dialogService) {
        super(appContainer);

        this.i18N          = i18N;
        this.repository    = repository;
        this.downloader    = downloader;
        this.dialogService = dialogService;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.listings.revisions.manage',
            'management.specifications.listings.revisions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.listing  = params.model;
        this.readonly = params.readonly;

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search:          (criteria) => this.search(criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            show:               {
                action:  (row) => this.openModal(row, ViewListingRevisionModal),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.revisions.manage', 'management.specifications.listings.revisions.view']),
            },
            edit:               {
                action:  (row) => this.openModal(row, EditListingRevisionModal),
                visible: (row) => !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.listings.revisions.manage', 'management.specifications.listings.revisions.edit']),
            },
            destroy:            !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.listings.revisions.manage', 'management.specifications.listings.revisions.delete']),
            destroySelected:    !this.readonly,
            actionsContextMenu: true,
            actions:            [],
            options:            [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.listing-revisions'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.revisions.manage', 'management.specifications.listings.revisions.view']),
                },
            ],
            buttons:            [
                {
                    label:            'form.button.create-new',
                    icon:             'icon-plus3',
                    visible:          !this.readonly && this.appContainer.authenticatedUser.can(['management.specifications.listings.revisions.manage', 'management.specifications.listings.revisions.create']),
                    action:           () => this.openModal(this, CreateListingRevisionModal),
                    outside_dropdown: true,
                    color:            'success',
                },
            ],
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:  'revision_number',
                    name:  'listing_revisions.revision_number',
                    title: 'form.field.revision-number',
                },
                {
                    data:            'effective_date',
                    name:            'listing_revisions.effective_date',
                    title:           'form.field.effective-date',
                    valueConverters: [
                        {
                            name:   'dateTimeFormat',
                            format: 'YYYY-MM-DD',
                        },
                    ],
                },
                {
                    data:            'closed_date',
                    name:            'listing_revisions.closed_date',
                    title:           'form.field.closed-date',
                    valueConverters: [
                        {
                            name:   'dateTimeFormat',
                            format: 'YYYY-MM-DD',
                        },
                    ],
                },
                {
                    data:    'created_by',
                    name:    'users.name',
                    title:   'form.field.created-by',
                    display: false,
                },
                {
                    data:            'created_at',
                    name:            'listing_revisions.created_at',
                    title:           'form.field.created-at',
                    display:         false,
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        return this.repository.search(this.listing.id, criteria);
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     * @param selectedRows
     */
    exportListing(filename = 'listing', selectedRows = null) {
        const filterModel = Object.assign({
            sorting: this.datatable.instance.getSortingColumn(),
        }, this.filterModel);

        if (selectedRows) {
            filterModel.selectedRows = selectedRows;
        }

        this.repository.export(this.listing.id, filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }
}
