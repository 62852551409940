import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ListingRevisionsRepository extends BaseRepository {
    baseUrl    = 'management/specifications/listings/revisions';
    listingUrl = 'management/specifications/listings';

    /**
     * Performs a search given some criteria
     *
     * @param listingId
     * @param criteria
     *
     * @returns {*}
     */
    search(listingId, criteria) {
        return this.httpClient.post(`${this.listingUrl}/${listingId}/revisions/search`, criteria);
    }

    /**
     * Creates a record
     *
     * @param listingId
     * @param data
     *
     * @returns {*}
     */
    create(listingId, data) {
        return this.httpClient.post(`${this.listingUrl}/${listingId}/revisions`, { ...data, listing_id: listingId });
    }

    /**
     * Exports a search given some criteria
     *
     * @param listingId
     * @param criteria
     *
     * @returns {*}
     */
    export(listingId, criteria) {
        return this.httpClient.rawPost(`${this.listingUrl}/${listingId}/revisions/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Fetches the next revision code number
     *
     * @param listingRevisionId
     * @return {*}
     */
    fetchNextRevisionNumber(listingRevisionId) {
        return this.httpClient.get(`${this.listingUrl}/${listingRevisionId}/next-revision-number`);
    }

    /**
     * Get all bulletins for ListingRevision
     *
     * @param listingRevisionId
     * @param date
     * @return {*}
     */
    getBulletins(listingRevisionId, date = null) {
        return this.httpClient.post(`${this.baseUrl}/${listingRevisionId}/get-bulletins`, { date });
    }

    /**
     * Fetches the latest selected bulletins
     *
     * @param listingId
     * @return {*}
     */
    latestSelectedBulletins(listingId) {
        return this.httpClient.get(`${this.listingUrl}/${listingId}/latest-selected-bulletins`)
    }

}
