import { inject }                    from 'aurelia-framework';
import { PLATFORM }                  from 'aurelia-pal';
import { BaseFormViewModel }         from 'base-form-view-model';
import { FormSchema }                from 'modules/management/specifications/listings/form-schema';
import { ListingRevisionFormSchema } from 'modules/management/specifications/listings/listing-revisions/form-schema';
import { ListingsRepository }        from 'modules/management/specifications/listings/services/repository';
import { AppContainer }              from 'resources/services/app-container';

@inject(AppContainer, ListingsRepository, FormSchema, ListingRevisionFormSchema)
export class CreateListing extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.specifications.listings.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    create = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param revisionFormSchema
     */
    constructor(appContainer, repository, formSchema, revisionFormSchema) {
        super(appContainer);

        this.repository         = repository;
        this.formSchema         = formSchema;
        this.revisionFormSchema = revisionFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/listings/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.listings.manage',
            'management.specifications.listings.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.initialModel.listing_revision = this.revisionFormSchema.model(0);
        this.model.listing_revision        = this.revisionFormSchema.model(0);
        this.revisionFormSchema            = this.revisionFormSchema.schema(this);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'management.specifications.listings.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
