import { inject }                from 'aurelia-framework';
import { Listing }               from 'modules/management/models/listing';
import { ConcessionsRepository } from 'modules/management/specifications/concessions/services/repository';
import { SectorsRepository }     from 'modules/management/specifications/sectors/services/repository';

@inject(ConcessionsRepository, SectorsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param concessionsRepository
     * @param sectorsRepository
     */
    constructor(concessionsRepository, sectorsRepository) {
        this.concessionsRepository = concessionsRepository;
        this.sectorsRepository     = sectorsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Listing}
     */
    model() {
        let model = new Listing();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.number = {
            type:       'number',
            key:        'number',
            label:      'form.field.number',
            size:       12,
            attributes: {
                readonly: readonly,
            },
        };

        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         12,
            remoteSource: () => this.concessionsRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        return [
            [this.number],
            [this.concession_id],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
